<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("slot-create.header") }}</h3>
      <SlotForm :instance="slot" :onSubmit="onSubmit" />
    </div>
  </div>
</template>

<script>
import InitSlotCreateInteractor from "@/business/slot-create/init-slot-create";
import SubmitFormInteractor from "@/business/slot-create/submit-form";
import SlotCreateScreenController from "@/adapters/controllers/screen-slot-create";
import SlotForm from "@/application/components/slots/SlotForm";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "slot-create",
  components: { SlotForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initSlotCreate: null,
        submitForm: null
      },
      isLoading: false,
      data: null,
      slot: {
        name: ""
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(SlotCreateScreenController);

    //{ INTERACTORS
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    this.interactors.initSlotCreate = this.$injector.get(
      InitSlotCreateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initSlotCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.interactors.submitForm.handle(this.slot);
    }
  }
};
</script>

<style lang="scss"></style>
